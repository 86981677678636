<template lang="pug">
.hamburger(:class="{open: state}")
  span
  span
  span
  span
</template>

<script>
export default {
  name: 'HamburgerComponent',

  props: {
    state: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="sass" scoped>
.hamburger
  min-width: unset
  width: 20px
  height: 20px
  position: relative
  transform: rotate(0deg)
  transition: .5s ease-in-out
  cursor: pointer
  span
    display: block
    position: absolute
    height: 2px
    width: 100%
    background: $dark-primary-color
    border-radius: 0
    opacity: 1
    left: 0
    transform: rotate(0deg)
    transition: .25s ease-in-out
    &:nth-child(1)
      top: 0
    &:nth-child(2),
    &:nth-child(3)
      top: 8px
    &:nth-child(4)
      top: 16px
  &.open
    span
      &:nth-child(1),
      &:nth-child(4)
        top: 5px
        width: 0
        left: 50%
      &:nth-child(2)
        transform: rotate(45deg)
      &:nth-child(3)
        transform: rotate(-45deg)
</style>
